import React from 'react';

import './App.css';
import {Redirect, Route, Switch, withRouter} from 'react-router'

import Get from "./common/Get";


const app = function App() {
	return (
		<Switch>
			<Route path='/app'>
				<Get from={'/app'}/>
			</Route>
			<Route path='/1'>
				<Get from={'/myrealfood_app'}/>
			</Route>
			<Route path='/2'>
				<Get from={'/carlosriosq'}/>
			</Route>
			<Route path='/3'>
				<Get from={'/realfooding'}/>
			</Route>
			<Route path='/4'>
				<Get from={'/iphone13'}/>
			</Route>
			<Route path='/web'>
				<Get from={'/web'}/>
			</Route>
			<Route path='/'>
				<Get from={'/web'}/>
			</Route>
			<Route path='*'>
				<Redirect to='/'/>
			</Route>
		</Switch>
	);
}

export default withRouter(app);
