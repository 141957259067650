import React from 'react'

export default function Get(props) {

	const getMobileOperatingSystem = () => {
		let userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Windows Phone must come first because its UA also contains "Android"
		if (/windows phone/i.test(userAgent)) {
			return "Windows Phone";
		}

		if (/android/i.test(userAgent)) {
			return "Android";
		}

		// iOS detection from: http://stackoverflow.com/a/9039885/177710
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			return "iOS";
		}

		return "unknown";
	};

	let otherURL = 'https://myrealfood.app/plus/';
	let iOSURL = 'https://myrealfood.app/plus/';
	let androidURL = 'https://myrealfood.app/plus/';
	switch (props.from) {
		case '/app':
			androidURL = 'https://link.myrealfood.app/share/ZQr4XzzGP1ZafwSx7';
			iOSURL = 'https://link.myrealfood.app/share/ZQr4XzzGP1ZafwSx7';
			otherURL = 'https://myrealfood.app/plus/?s=plans&period=12';
			break;
		case '/myrealfood_app':
			androidURL = 'https://link.myrealfood.app/share/e7dHLmfJPdS3797i9';
			iOSURL = 'https://link.myrealfood.app/share/e7dHLmfJPdS3797i9';
			otherURL = 'https://myrealfood.app/plus/?s=plans&period=12';
			break;
		case '/carlosriosq':
			androidURL = 'https://link.myrealfood.app/share/6fwdX7uEcjqL6Wjw9';
			iOSURL = 'https://link.myrealfood.app/share/6fwdX7uEcjqL6Wjw9';
			otherURL = 'https://myrealfood.app/plus/?s=plans&period=12';
			break;
		case '/realfooding':
			androidURL = 'https://link.myrealfood.app/share/rNpG5EHgSjWRGjpp9';
			iOSURL = 'https://link.myrealfood.app/share/rNpG5EHgSjWRGjpp9';
			otherURL = 'https://myrealfood.app/plus/?s=plans&period=12';
			break;
		case '/iphone13':
			androidURL = 'https://link.myrealfood.app/share/oG61h9H6pK3DUgsP9';
			iOSURL = 'https://link.myrealfood.app/share/oG61h9H6pK3DUgsP9';
			otherURL = 'https://myrealfood.app/plus/?s=plans&period=12';
			break;
		case '/web':
			androidURL = 'https://myrealfood.app/plus/';
			iOSURL = 'https://myrealfood.app/plus/';
			otherURL = 'https://myrealfood.app/plus/';
			break;
		default:
			break;
	}

	return (
		<div style={{marginTop: '1em'}}>
			{getMobileOperatingSystem() === 'Android' && window.location.assign(androidURL)}
			{getMobileOperatingSystem() === 'iOS' && window.location.assign(iOSURL)}
			{getMobileOperatingSystem() === 'unknown' && window.location.assign(otherURL)}
		</div>
	)
}
